<template>
  <!-- Footer -->
  <footer class="footer has-background-trans mt-6">
    <div class="content has-text-centered">
      <p class="subtitle is-6">
        Designed and coded with ☕ &
        <span class="has-text-danger">❤</span> by Julian. All Rights Reserved.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footer"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  padding: 6rem;
}
</style>
