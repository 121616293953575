<template>
  <!-- Navigation -->
  <nav
    class="navbar main-nav is-transparent py-6"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <a class="navbar-item" @click="handleViewPage('/')">
        <img src="img/logo.svg" alt="Logo" />
      </a>
      <!-- <div class="is-hidden-touch navbar-item tag is-light is-link">Available for jobs</div> -->
      <a
        @click="toggleMobileMenu()"
        role="button"
        class="navbar-burger" :class="{ 'is-active': isActive }"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-menu" :class="{ 'is-active': isActive }">
      <div class="navbar-end">
        <a class="navbar-item" @click="handleViewPage('/')">features</a>
        <a class="navbar-item" @click="handleViewPage('work')">other works</a>
        <a class="navbar-item" @click="handleViewPage('/about')">about</a>
        <a class="navbar-item" @click="handleViewPage('/skill')">skill</a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "navbar",
  props: {
    msg: String,
  },
  data() {
    return {
      isActive:false,
    };
  },
  methods: {
    toggleMobileMenu() {
      this.isActive = !this.isActive
    },
    handleViewPage(path) {
      this.$router.push(path);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-nav .navbar-menu .navbar-end .navbar-item {
  padding: 0 2rem;
}
a.navbar-item {
  letter-spacing: 0.1rem;
}

a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover,
a.navbar-item.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
.navbar-link.is-active {
  background-color: transparent;
  color: #003acf;
  text-decoration: underline;
  text-underline-offset: 1rem;
}

@media screen and (max-width: 1023px) {
  .navbar {
    padding:0 !important;
  }
  .navbar-menu {
      background-color: white;
      box-shadow: 0px 20px 20px rgb(10 10 10 / 10%);
      /* padding: 2rem 0; */
      position: absolute;
      width: 100%;
  }
  .is-active .navbar-end a.navbar-item {
    padding: 1rem !important;
  }
}
</style>
