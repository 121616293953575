<template>
  <div class="about animate__animated animate__fadeIn animate__slow">
    <section class="hero is-fullheight">
      <!-- Hero head: will stick at the top -->
      <div class="hero-head">
        <div class="container is-max-widescreen">
          <Navbar />
        </div>
      </div>

      <!-- Hero content: will be in the middle -->
      <div class="hero-body">
        <div class="container is-max-widescreen">
          <div class="columns">
            <div class="column is-three-fifths">
              <div class="content is-medium">
                <h1 class="has-text-black mb-6 is-size-3-mobile">
                  "Love design and build inspiring products."
                </h1>
                <!-- <hr /> -->
                <p>
                  Hey, this is Julian - a full-stack designer currently living
                  in Vancouver.
                </p>
                <p>
                  As a designer, I mainly work on UIUX design with many startups
                  and companies. I am passionate about solving tough problems by
                  combining thoughtful research, data-backed analysis, and
                  minimalist design. Also, contributing multiple perspectives on
                  problem-solving or product designing to my team is always my
                  belief and passion.
                </p>
                <p>
                  Minimalist design is the design principle of all my works. I
                  believed that good design is "less is more", natural, and
                  pleasing. And good design can better arouse people's desire to
                  use the products. Also, I enjoy exploring different new techs
                  and design concepts and using them to build creative and
                  practical products that make people's lives easier.
                </p>
                <p>
                  Apart from design, my day is more about cooking my favorite
                  foods, listening to podcasts, or playing the ukulele. Oh and
                  one more thing, enjoy my delicious coffee.
                </p>

                <div class="mt-6 contact-me has-text-weight-bold">
                  <p>
                    Interested in my works or backgrounds? <br>Let’s chat! Feel free
                    to
                    <a
                      class="has-text-weight-bold"
                      href="mailto:julianatwork365@gmail.com"
                      >contact me ➔</a
                    >.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- <a rel="ar" href="img/demo.usdz">ar</a> -->
        </div>
      </div>
    </section>

    <div class="is-about-bg">
      <figure class="image">
        <img src="img/about-bg.svg" />
      </figure>
    </div>

    <Footer />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "about",
  props: {
    msg: String,
  },
  components: {
    Navbar,
    Footer,
  },
  mounted() {
    this.track();
  },
  methods: {
    track() {
      this.$gtag.pageview("/about");
      this.$gtag.query('event', 'screen_view',{
        app_name: 'juliandesign.io',
        screen_name: 'About',
      })
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.is-about-bg {
  position: absolute;
  top:0;
  right:0;
  z-index: -1;
}
</style>
